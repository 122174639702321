import '../../resources/sass/global.scss';
import '../styles/global.scss';
import 'game-specific-scss';
import '../../resources/sass/app.scss';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { sendPageView } from '../../resources/js/helpers/analytics';
import '../helpers/sentry';
import Script from 'next/script';
import '../../resources/js/lang/js-localization';
import { AppPropsType } from 'next/dist/shared/lib/utils';
import config, { GameSlug, toGameSlug } from '../config';
import usePlaywire, { PlaywireContextProvider } from '../contexts/usePlaywire';

type CustomAppProps = Pick<AppPropsType, 'Component'> & {
  pageProps: AppPropsType['pageProps'] & {
    translations?: Record<string, string>;
    googleAnalytics?: {
      id: string;
    };
  };
};

export default function App({
  Component,
  pageProps,
}: CustomAppProps): JSX.Element {
  // @ts-expect-error apparently cannot extend globalThis types
  global.locale = 'en';
  // @ts-expect-error apparently cannot extend globalThis types
  global.trans = global.trans ? global.trans : (x) => x;
  if (pageProps.translations) {
    // @ts-expect-error apparently cannot extend globalThis types
    global.Lang.addMessages({
      en: pageProps.translations,
    });
  }

  return (
    <>
      <PlaywireContextProvider
        publisherId={config.playwirePublisherId}
        siteId={config.playwireSiteId}
      >
        <Component {...pageProps} />
        <GameSlugClassUpdater />
        {pageProps.googleAnalytics ? (
          <GoogleAnalytics id={pageProps.googleAnalytics.id} />
        ) : null}
      </PlaywireContextProvider>
    </>
  );
}

function gameSlugToClassName(gameSlug: GameSlug | null): string | null {
  switch (gameSlug) {
    case null: {
      return null;
    }
    case 'wow':
    case 'classic-wrath':
    case 'classic-sod':
      return 'warcraft';
    case 'ffxiv':
      return 'ff';
    default:
      return gameSlug;
  }
}

function maybeAddGameSlug(ref: MutableRefObject<string | null>) {
  const { pathname } = location;

  const [maybeSlug] = pathname.split('/').filter(Boolean);
  const className = gameSlugToClassName(toGameSlug(maybeSlug));

  [document.body, document.documentElement].forEach((element) => {
    if (className && !element.classList.contains(className)) {
      element.classList.add(className);
    }

    // remove e.g. warcraft when going from /warcraft to /eso
    // but don't remove it when navigating within a game
    if (
      ref.current &&
      ref.current !== className &&
      element.classList.contains(ref.current)
    ) {
      element.classList.remove(ref.current);
    }
  });

  ref.current = className;
}

function GameSlugClassUpdater(): null {
  const router = useRouter();
  const previousClassNameRef = useRef<string | null>(null);

  useEffect(function onLoad() {
    maybeAddGameSlug(previousClassNameRef);
  }, []);

  useEffect(
    function onRouteChange() {
      const handler = () => {
        maybeAddGameSlug(previousClassNameRef);
      };

      router.events.on('routeChangeComplete', handler);

      return () => {
        router.events.off('routeChangeComplete', handler);
      };
    },
    [router]
  );

  return null;
}

type GoogleAnalyticsProps = {
  id: string;
};

function GoogleAnalytics({ id }: GoogleAnalyticsProps): JSX.Element | null {
  const router = useRouter();
  const { isEnabled: isPlaywireEnabled } = usePlaywire();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      sendPageView({
        page_path: url,
        page_title: document.title,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  if (!id) {
    return null;
  }

  const playwireScript = isPlaywireEnabled
    ? `
      gtag('config', 'G-9E20R7RGMW', { 'send_page_view': false });
      gtag('event', 'ramp_js', {
          'send_to': 'G-9E20R7RGMW',
          'pageview_id': Date.now().toString()
      });
    `
    : '';

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
      />
      <Script
        id='gtag-init'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${id}', {
              page_path: window.location.pathname,
            });
            ${playwireScript}
          `,
        }}
      />
    </>
  );
}
