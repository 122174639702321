const config = {
  warcraftLogsBaseUrl: process.env.WARCRAFT_LOGS_BASE_URL ?? '/',
  warcraftLogsClassicBaseUrl: process.env.WARCRAFT_LOGS_CLASSIC_BASE_URL ?? '/',
  warcraftLogsSeasonOfDiscoveryBaseUrl:
    process.env.WARCRAFT_LOGS_SEASON_OF_DISCOVERY_BASE_URL ?? '/',
  ffLogsBaseUrl: process.env.FF_LOGS_BASE_URL ?? '/',
  esoLogsBaseUrl: process.env.ESO_LOGS_BASE_URL ?? '/',
  swtorLogsBaseUrl: process.env.SWTOR_LOGS_BASE_URL ?? '/',
  siteName: process.env.SITE_NAME,
  isBeta: process.env.IS_BETA === 'true',
  isProduction: process.env.IS_PRODUCTION === 'true',
  isCI: process.env.CI === 'true',
  playwirePublisherId: process.env.NEXT_PUBLIC_PLAYWIRE_PUBLISHER_ID ?? '',
  playwireSiteId: process.env.NEXT_PUBLIC_PLAYWIRE_SITE_ID ?? '',
} as Config;

type Config = {
  warcraftLogsBaseUrl: string;
  warcraftLogsClassicBaseUrl: string;
  warcraftLogsSeasonOfDiscoveryBaseUrl: string;
  ffLogsBaseUrl: string;
  esoLogsBaseUrl: string;
  swtorLogsBaseUrl: string;
  siteName: 'archon';
  isBeta: boolean;
  isProduction: boolean;
  isCI: boolean;
  playwirePublisherId: string;
  playwireSiteId: string;
};

export const gameSlugs = [
  'wow',
  'classic-wrath',
  'classic-sod',
  'ffxiv',
  'eso',
  'swtor',
] as const;
export type GameSlug = (typeof gameSlugs)[number];
export const toGameSlug = (gameSlug: string): GameSlug | null => {
  if (gameSlugs.includes(gameSlug as GameSlug)) {
    return gameSlug as GameSlug;
  }

  return null;
};

export function getApiClientId(gameSlug?: GameSlug): string {
  switch (gameSlug) {
    case 'wow':
    case 'classic-wrath':
    case 'classic-sod':
      return process.env.WARCRAFT_LOGS_API_CLIENT_ID as string;
    case 'ffxiv':
      return process.env.FF_LOGS_API_CLIENT_ID as string;
    case 'eso':
      return process.env.ESO_LOGS_API_CLIENT_ID as string;
    case 'swtor':
      return process.env.SWTOR_LOGS_API_CLIENT_ID as string;
  }

  return process.env.RPGLOGS_API_CLIENT_ID as string;
}

export function getApiClientSecret(gameSlug?: GameSlug): string {
  switch (gameSlug) {
    case 'wow':
    case 'classic-wrath':
    case 'classic-sod':
      return process.env.WARCRAFT_LOGS_API_CLIENT_SECRET as string;
    case 'ffxiv':
      return process.env.FF_LOGS_API_CLIENT_SECRET as string;
    case 'eso':
      return process.env.ESO_LOGS_API_CLIENT_SECRET as string;
    case 'swtor':
      return process.env.SWTOR_LOGS_API_CLIENT_SECRET as string;
  }

  return process.env.RPGLOGS_API_CLIENT_SECRET as string;
}

export function getBaseUrl(gameSlug?: GameSlug): string {
  switch (gameSlug) {
    case 'wow':
      return config.warcraftLogsBaseUrl;
    case 'classic-wrath':
      return config.warcraftLogsClassicBaseUrl;
    case 'classic-sod':
      return config.warcraftLogsSeasonOfDiscoveryBaseUrl;
    case 'ffxiv':
      return config.ffLogsBaseUrl;
    case 'eso':
      return config.esoLogsBaseUrl;
    case 'swtor':
      return config.swtorLogsBaseUrl;
  }

  return process.env.RPGLOGS_BASE_URL as string;
}

export function isProductionOrBeta(): boolean {
  return config.isProduction; // || config.isBeta;
}

export default config;
