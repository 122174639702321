import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import Script from 'next/script';
import { PlaywireAdUnit } from '@resources/js/typings/viewModels/playwireAdViewModel';
import { isProductionOrBeta } from '../config';

type Context = {
  isLoading: boolean;
  isAvailable: boolean;
  isEnabled: boolean;
  destroy: (adUnit: PlaywireAdUnit) => Promise<void>;
  display: (adUnit: PlaywireAdUnit) => Promise<void>;
};

export const PlaywireContext = createContext<Context | undefined>(undefined);

type Props = {
  publisherId: string;
  siteId: string;
  children: ReactNode;
};

const log = (...rest: unknown[]) => {
  if (isProductionOrBeta()) return;

  console.info('Playwire', Date.now(), ...rest);
};

const display = async (adUnit: PlaywireAdUnit): Promise<void> => {
  if (!window.ramp) return;

  log('display', adUnit);
  await window.ramp.addUnits?.([adUnit]);
  await window.ramp.displayUnits?.();
};

const destroy = async (adUnit: PlaywireAdUnit): Promise<void> => {
  if (!window.ramp) return;

  log('destroy', adUnit.type);
  Object.entries(window.ramp.settings?.slots ?? {})
    .map(([slotName, slot]) => {
      return { slotName, slot };
    })
    .filter(
      ({ slotName, slot }) =>
        slotName === adUnit.type ||
        slot.type === adUnit.type ||
        slot.element?.parentElement?.id === adUnit.selectorId
    )
    .forEach(({ slotName }) => window.ramp.destroyUnits?.(slotName));
};

export const PlaywireContextProvider = ({
  publisherId,
  siteId,
  children,
}: Props): JSX.Element => {
  const isEnabled = publisherId !== '' && siteId !== '';
  const [isLoading, setIsLoading] = useState(isEnabled);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    window.ramp = window.ramp || {};
    window.ramp.que = window.ramp.que || [];
    window.ramp.passiveMode = true;
    window.ramp.onReady = () => {
      setIsLoading(false);
      setIsAvailable(true);
    };
  }, [setIsLoading, setIsAvailable]);

  return (
    <PlaywireContext.Provider
      value={{ isEnabled, isLoading, isAvailable, display, destroy }}
    >
      {isEnabled && (
        <Script
          src={`https://cdn.intergient.com/${publisherId}/${siteId}/ramp.js`}
          onError={() => {
            console.warn('ramp is unavailable');
            setIsLoading(false);
            setIsAvailable(false);
          }}
        />
      )}
      {children}
    </PlaywireContext.Provider>
  );
};

const usePlaywire = (): Context => {
  const context = useContext(PlaywireContext);
  if (context === undefined) {
    throw new Error(
      'usePlaywire must be used within an PlaywireContextProvider'
    );
  }
  return context;
};

export default usePlaywire;
